<template>
  <div>
    <b-card id="card-tiktok-filters" class="mb-0">
      <select-network-search 
        :networks_avaibles="networks_avaibles"
        :select_network_dad="select_network"
        @update_select_network="updateSelectNetwork"
        class="select-network-search"
      ></select-network-search>
      <b-row class="m-0 w-100">
        <!-- audience location -->
        <b-col class="col-md-6 col-lg filter-outline p-0" cols="12">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100" ref="filter_audience_location">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left align-middle color-gray-light d-block mb-1">
                    <span class="search-Map-Pin4"></span> {{$t("search.audience_location")}} 
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{text_audience_location}} </p>
              </div>
            </template>
            <b-dropdown-header :key="update_location" class="b-dropdown-header-location-filter-padding">
              <div v-for="(location, index) in audience_locations" :key="index">
                <audience-location-filter
                  :key="change_audience_location"
                  :show_remove="index > 0"
                  :index="index"
                  :location="location"
                  :main_locations_filter="main_locations_filter"
                  :only_countries="true"
                  @changeLocationAudienceChild="changeLocationAudience"
                  @removeAudienceLocationChild="removeAudienceLocation"
                />
              </div>
              <div v-if="Object.keys(this.audience_locations[0]).length > 0" class="p-custom">
                <hr/>
                <b-link v-show="this.audience_locations.length < 3" @click="addAudienceLocation()" class="text-blue">{{$t('search.addLocation')}}</b-link>
                <div v-show="this.audience_locations.length === 3" class="white-space-wrap">{{$t('search.max3Locations')}}</div>
              </div>
            </b-dropdown-header>
          </b-dropdown>
        </b-col>

        <!-- Audience age -->
        <b-col class="col-md-6 col-lg filter-outline p-0" cols="12">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left color-gray-light d-block mb-1">
                    <span class="search-Busy-1"></span>{{$t("search.audience_age")}}
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{audience_ages[1] === 65 && audience_ages[0] === 10 ?  $t('search.realty.any') : getAudienceAge()}}</p>
              </div>
            </template>
            <b-dropdown-header class="p-05">
              <div v-if="audience_ages[0] !== 10 && audience_ages[1] === 65"><span class="avenir-medium">≥</span> <strong>{{audience_ages[0]}}</strong> <span class="avenir-medium">{{$t('tiktok.years')}}</span></div>
              <div v-else-if="audience_ages[1] !== 65 && audience_ages[0] === 10" class="text-right"><span class="avenir-medium">≤</span> <strong>{{audience_ages[1]}}</strong> <span class="avenir-medium">{{$t('tiktok.years')}}</span></div>
              <strong v-else-if="audience_ages[0] === 10 && audience_ages[1] === 65">{{ $t('search.anyAge')}}</strong>
              <div v-else class="d-flex justify-content-between">
                <div class="">{{audience_ages[0]}} <span class="avenir-medium">{{$t('tiktok.years')}}</span></div>
                <div class="">{{audience_ages[1]}} <span class="avenir-medium">{{$t('tiktok.years')}}</span></div>
              </div>

              <slider-range :tooltip="'none'" class="mt-1" :init_range="audience_ages" :interval="1" :max_value="65" :min_value="10" identifier="audience_ages" @changeSlide="changeSlideComponent" :key="audience_ages[0] + audience_ages[1]"/>

              <div class="mb-1 mt-2" v-if="showPercentageAgeAudience()"><span class="avenir-medium">{{$t("search.moreOf")}}</span> {{percentage_audience_age}}% <span class="avenir-medium">{{$t("search.ofAudience")}}</span></div>
              <component
                :is="vue_slider"
                class="mt-1"
                v-if="showPercentageAgeAudience()"
                v-model="percentage_audience_age"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="99"
                :min="1"
                :interval="1"
                @drag-end="changeSlide"
                :tooltip="'none'"
              />
            </b-dropdown-header>
          </b-dropdown>
        </b-col>  

        <!-- Audience gender -->
        <b-col  class="col-md-6 col-lg filter-outline p-0" cols="12">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100" ref="filter_gender">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left align-middle color-gray-light mb-1 d-block">
                    <span class="search-intersex"></span> {{$t("search.audience_gender")}} 
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{audience_gender !== null ? audience_gender.text : $t('search.realty.any')}}</p>
              </div>
            </template>
            <b-dropdown-header :key="update_gender">
              <div v-for="(gender, index) in $t('instaGen')" :key="index" ref="gender_dropdown">
                <div 
                  :class="`button-gender 
                  ${index === 0 ? 'border-radius-top' : ''} 
                  ${index === $t('instaGen').length -1 ? 'border-radius-bottom' : ''}
                  `"
                  @click="setAudienceGender(gender, index)"
                >
                  {{gender.text}}
                </div>
              </div>
              
              <div v-if="audience_gender !== null ? audience_gender.value !== null : audience_gender !== null">
                <div class="mt-2 white-space-wrap avenir-medium">{{$t('search.more_than')}}<strong>{{percentage_gender}}</strong>%{{$t('search.of_audience')}}</div>
                <component
                  :is="vue_slider"
                  class="mt-1"
                  v-model="percentage_gender"
                  :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :max="100"
                  :min="55"
                  :interval="5"
                  @drag-end="changeSlide"
                  :tooltip="'none'"
                />
              </div>
            </b-dropdown-header>
          </b-dropdown>
        </b-col>

        <!-- Average views-->
        <b-col class="col-md-6 col-lg filter-outline p-0" cols="12">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left align-middle color-gray-light mb-1 d-block">
                    <b-icon class="search-icon icon-size-14" icon="eye"/><span class="ml-1">{{$t("search.averageViewsTiktok")}}</span>
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{views[0] === 0 && views[1] === 10000000 ? $t('search.realty.any') : `${getFormat(views[0])} - ${getFormat(views[1])}`}}</p>
              </div>
            </template>
            <b-dropdown-header class="p-05"> 
              <view-avg-slider ref="view_avg_slider" :init_range="views" :interval="5000" :max_value="10000000" :min_value="0" identifier="views" @changeSlide="changeSlideComponent" :key="update_view_avg_slider"/>
            </b-dropdown-header>
          </b-dropdown>
        </b-col>
        <!-- Boton more filters -->
        <div class="d-flex align-items-center container-more-filters">
          <b-button variant="outline-secondary" class="d-flex align-items-center w-100" @click="open_sidebar = true"><b-icon icon="filter" class="mr-03"/>{{$t('search.more_filters')}}</b-button>
        </div>
      </b-row>
    </b-card>
    <search-by-actions
      :data="{
        total_results,
        loading,
        searched,
        categories,
        disabled_custom_category: true
      }"
      @sendFilters="sendFilters()"
      @add_keyword_filter="addKeywordFilter"
      ref="search_by_actions"
      :key="update_search_by"
    ></search-by-actions>
    <apply-filters :filters="checkFiltersUsed()" @deleteFilter="deleteFilter" @updateModel="updateModel"/>
    <b-sidebar 
      v-model="open_sidebar"
      backdrop
      shadow
      right
      width="700px"
      body-class="d-flex flex-column background-white"
      no-header
      footer-class="background-white footer-sidebar-filters"
      class="sidebar-filters-nets"
    >
    <div>
      <feather-icon
          class="close-component"
          icon="XIcon"
          size="16"
          @click="open_sidebar = false"
      />
    </div>
      <b-row class="p-1">
        <b-col class="col-12 col-md">
          <div>
            <h4 class="blueC"><strong>{{$t("search.influencer")}}</strong></h4>
          </div>

          <!-- Tamaño de audiencia -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('audience_size')">
                <span class="search-Users"></span><strong>{{$t("search.audienceSize")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_audience_size"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_audience_size"/>
              </span>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_audience_size">
              <b-dropdown :text="text_followers" no-caret block variant="outline-dark" menu-class="w-100" ref="filter_folllowers">
                <b-dropdown-header>
                  <followers-filter @changeFollowers="changeFollowers" :key="update_followers_filter"/>
                </b-dropdown-header>
              </b-dropdown>
            </div>
          </div>

          <!-- Genero -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('influencer_gender')">
                <span class="search-intersex"></span><strong> {{$t("search.influencerGender")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_influencer_gender"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_influencer_gender"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_influencer_gender">
              <options-filters :options="$t('instaGen')" @optionsFilter="filterGender" :key="update_influencer_gender"></options-filters>
            </div>
          </div>

          <!-- Ubicación del influencer -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('influencer_location')">
                <span class="search-Map-Pin4"></span><strong> {{$t("search.influencerLocation")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_influencer_location"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_influencer_location"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1 position-relative" ref="collapse_filters_influencer_location">
              <b-spinner
                v-if="loading_location_influencer"
                variant="primary"
                label="Text Centered"
                class="spinner-location-influencer-tiktok"
              />
              <component
                :is="vue_autosuggest"
                :suggestions="influencer_locations"
                :limit="10"
                :input-props="input_locations"
                @input="inputInfluencerLocation"
                v-model="influencer_location"
                :get-suggestion-value="getInfluencerLocation"
                :should-render-suggestions="shouldRenderSuggestions"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                </template>

                <template slot="before-suggestions">
                  <div class="m-1" v-if="influencer_location !== ''">
                    {{$t("search.searchingBy")}}: {{influencer_location}}
                  </div>
                  <hr v-if="influencer_location !== ''">
                </template>
                <template slot="after-suggestions">
                  <div class="m-1" v-if="no_locations_influencer">
                  {{$t("search.noResults")}}
                  </div>
                </template>
              </component>
            </div>
          </div>

          <!-- Idioma del influencer -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('influencer_language')">
                <span class="search-Globe-1"></span><strong> {{$t("search.influencerLanguage")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_influencer_language"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_influencer_language"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_influencer_language">
              <b-form-select
                v-model="influencer_language"
                :options="$t('instaIdioma')"
              />
            </div>
          </div>

          <!-- Solo verificados -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('is_verified')">
                <span class="search-Check-1"></span><strong> {{$t("search.onlyveri")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_is_verified"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_is_verified"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_is_verified">
              <options-filters :options="$t('instaVerificado')" @optionsFilter="filterVerified" :key="update_is_verified"></options-filters>
            </div>
          </div>

          <!-- Tienen información de contacto -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('info_contact')">
                <span class="search-Information-booklet"></span><strong> {{$t("search.contact")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_info_contact"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_info_contact"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_info_contact">
              <options-filters :options="$t('instaContacto')" @optionsFilter="filterHasContacts" :key="update_info_contact"></options-filters>
            </div>
          </div>

          <div class="py-2" v-if="role !== 'espejo'">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('prices')">
                <strong><b-icon class="search-icon" icon="cash"/>{{$t("search.priceInfluencer")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_prices"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_prices"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_prices">
              <p class="mt-1">{{getLabelPrices()}}</p>
              <slider-range class="mt-3" :init_range="prices" :interval="10000" :max_value="1000000" :min_value="0" identifier="prices" @changeSlide="changeSlideComponent" :key="prices[0] + prices[1]"/>

            </div>
          </div>
        </b-col>

        <b-col class="col-12 col-md">
          <!-- Filtros rendimiento -->
          <div>
            <h4 class="blueC"><strong>{{$t("search.performance")}}</strong></h4>
          </div>

          <!-- Engagement rate -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('engagement_rate')">
                <span class="search-Heart-Beat"></span><strong> {{$t("search.engagementRate")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_engagement_rate"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_engagement_rate"/>
              </span>              
              <div class="my-1" v-if="engagement_rate > 0">{{engagement_rate}} {{$t("search.engagementRate")}}</div>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_engagement_rate">
              <component
                :is="vue_slider"
                :tooltip="'always'"
                class="mt-3"
                v-model="engagement_rate"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="40"
                :min="0"
                :interval="0.5"
                @drag-end="changeSlide"
                :tooltip-formatter="'{value}'"
              />
            </div>
          </div>

          <!-- Aumento de seguidores -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('follower_days')">
                  <span class="search-Increasing-Chart1"></span><strong> {{$t("search.increaseFollowers")}}</strong>
                  <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_follower_days"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_follower_days"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_follower_days">
              <b-form-select
                v-model="followers_days"
                :options="$t('days')"
              />
              <div class="my-1" v-if="followers_days > 0">{{$t("search.increase")}} {{percentage_followers_growth}}%</div>
              <component
                :is="vue_slider"
                :tooltip="'always'"
                class="mt-3"
                v-if="followers_days"
                v-model="percentage_followers_growth"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="99"
                :min="1"
                :interval="1"
                @drag-end="changeSlide"
                :tooltip-formatter="'{value}%'"
              />
            </div>
          </div>

          <!-- Crecimiento de me gusta -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('increase_likes')">
                  <strong><b-icon class="search-icon" icon="hand-thumbs-up"/>{{$t("search.increaseLikes")}}</strong>
                  <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_increase_likes"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_increase_likes"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_increase_likes">
              <b-form-select
                v-model="increase_likes"
                :options="$t('days')"
              />
              <div class="my-1" v-if="increase_likes > 0">{{increase_likes}} {{$t("search.likes")}}</div>
              <component
                :is="vue_slider"
                :tooltip="'always'"
                class="mt-3"
                v-if="increase_likes"
                v-model="percentage_increase_likes"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="99"
                :min="1"
                :interval="1"
                @drag-end="changeSlide"
                :tooltip-formatter="'{value}%'"
              />
            </div>
          </div>

          <!-- Total de me gusta -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('total_likes')">
                <strong><b-icon class="search-icon" icon="graph-up"/>{{$t("search.totalLikes")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_total_likes"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_total_likes"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_total_likes">
              <p class="mt-1">{{getLabelLikes()}}</p>
              <slider-range class="mt-3" :init_range="total_likes" :interval="100" :max_value="10000000" :min_value="0" identifier="total_likes" @changeSlide="changeSlideComponent" :key="total_likes[0] + total_likes[1]"/>

            </div>
          </div>

          <!-- Promedio de me gusta en 30 días -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('average_likes')">
                <strong><b-icon class="search-icon" icon="hand-thumbs-up"/>{{$t("search.averageLikes")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_average_likes"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_average_likes"/>
              </span>              
              <div class="my-1" v-if="average_likes > 0">{{average_likes}} {{$t("search.likes")}}</div>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_average_likes">
              <component
                :is="vue_slider"
                class="mt-3"
                :tooltip="'always'"
                v-model="average_likes"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="100000"
                :min="0"
                :interval="1000"
                @drag-end="changeSlide"
                :tooltip-formatter="formatter"
              >
              </component>
            </div>
          </div>

          <!-- Promedio de comentarios en 30 días -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('average_comments')">
                <strong><b-icon class="search-icon" icon="chat"/>{{$t("search.averageCommentsTiktok")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_average_comments"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_average_comments"/>
              </span>              
              <div class="my-1" v-if="average_comments > 0">{{average_comments}} {{$t("search.comments")}}</div>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_average_comments">
              <component
                :is="vue_slider"
                class="mt-3"
                :tooltip="'always'"
                v-model="average_comments"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="100000"
                :min="0"
                :interval="1000"
                @drag-end="changeSlide"
                :tooltip-formatter="formatter"
              >
              </component>
            </div>
          </div>

          <!-- Promedio de veces compartido en 30 días -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('average_shares')">
                <strong><b-icon class="search-icon" icon="share"/>{{$t("search.averageShares")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_average_shares"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_average_shares"/>
              </span>             
              <div class="my-1" v-if="average_shares > 0">{{average_shares}} {{$t("search.shares")}}</div> 
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_average_shares">
              <component
                :is="vue_slider"
                class="mt-3"
                v-model="average_shares"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="100000"
                :min="0"
                :interval="1000"
                @drag-end="changeSlide"
                :tooltip-formatter="formatter"
              >
              </component>
            </div>
          </div>

          <!-- Publicación más reciente -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('most_recent')">
                <span class="search-Calendar"></span><strong> {{$t("search.mostRecent")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_most_recent"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_most_recent"/>
              </span>              
              <span class="my-1" v-if="most_recent > 0"> {{most_recent}} {{$t("search.days")}}</span>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_most_recent">
              <component
                :is="vue_slider"
                :tooltip="'always'"
                class="mt-3"
                v-model="most_recent"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="100"
                :min="0"
                :interval="1"
                @drag-end="changeSlide"
                :tooltip-formatter="'{value}'"
              />
            </div>
          </div>

          <!-- Promedio de visualizaciones -->
          <div class="py-2">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('views')">
                <strong><b-icon class="search-icon" icon="sort-numeric-up-alt"/>{{$t("search.point_audience")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_views"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_views"/>
              </span>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_views">
              <p class="text-left font-weight-bold">{{quality_audience !== null ? $t(`qualityAudience`).find((item) => item.value === quality_audience).text : $t('search.realty.any')}}</p>
              <options-filters ref="options_filters_gender" :vertical="true" :options="$t('qualityAudience')" @optionsFilter="filterQualityAudience" :key="update_aqs"></options-filters>
            </div>              
          </div>

          <!-- Brand mentions -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('mentioned_any')">
                <span class="search-User-Store"></span><strong> {{$t("search.yt.textMentioned")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_mentioned_any"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_mentioned_any"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_mentioned_any">
              <div>
                <b-form-checkbox v-model="mentioned_any">
                  {{$t('search.yt.hasMentioned')}}
                </b-form-checkbox>
              </div>
              <div class="mt-1">
                <options-filters :options="$t('optionsMencionado')" @optionsFilter="filterMentionedAny" :key="update_mention"></options-filters>
                <div class="position-relative">
                  <b-spinner
                    v-if="loading_brands && mention !== null"
                    variant="primary"
                    label="Text Centered"
                    class="spinner-mention-instagram"
                  />
                  <component
                    :is="vue_autosuggest"
                    class="mt-1"
                    v-if="!mentioned_any"
                    :suggestions="brands"
                    :limit="10"
                    :input-props="input_brands"
                    :key="change_language"
                    @input="inputBrands"
                    v-model="brand"
                    :get-suggestion-value="getBrand"
                    :should-render-suggestions="shouldRenderSuggestions"
                  >
                    <template slot-scope="{suggestion}">
                      <chat-contact
                        :user="suggestion.item"
                        tag="li"
                        is-chat-contact
                      />
                    </template>

                    <template slot="before-suggestions">
                      <div class="m-1" v-if="brand !== ''">
                        {{$t("search.searchingBy")}}: {{brand}}
                      </div>
                      <hr v-if="brand !== ''">
                    </template>
                    <template slot="after-suggestions">
                      <div class="m-1" v-if="no_brands">
                        {{$t("search.noResults")}}
                      </div>
                    </template>
                  </component>
                </div>
              </div>
            </div>
          </div>

          <!-- Similar to-->
          <div class="py-2">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('similar')">
                <span class="search-Eyedropper"></span><strong> {{$t("search.similar")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_similar"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_similar"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1 position-relative" ref="collapse_filters_similar">
              <b-spinner
                v-if="loading_similars"
                variant="primary"
                label="Text Centered"
                class="spinner-mention-instagram"
              />
              <component
                :is="vue_autosuggest"
                v-model="similar"
                :suggestions="similars"
                :input-props="input_brands"
                :key="change_language"
                :limit="10"
                @input="inputSimilar"
                @selected="getSimilarValue"
                :get-suggestion-value="getValueInput"
                :should-render-suggestions="shouldRenderSuggestions"
              >
                <template slot-scope="{suggestion}">
                  <chat-contact
                    :user="suggestion.item"
                    tag="li"
                    is-chat-contact
                  />
                </template>

                <template slot="before-suggestions">
                  <div class="m-1" v-if="similar !== ''">
                    {{$t("search.searchingBy")}}: {{similar}}
                  </div>
                  <hr v-if="similar !== ''">
                </template>
                <template slot="after-suggestions">
                  <div class="m-1" v-if="no_similars">
                    {{$t("search.noResults")}}
                  </div>
                </template>
              </component>
            </div>
          </div>

        </b-col>
      </b-row>
      <template #footer>
        <!-- Button submit -->
        <b-col cols="12" class="button-submit-searcher col-12 mb-3 d-flex flex-wrap">
          <button-submit-filters :searched="searched" :loading="loading" :text="getText()" :total_results="total_results" @sendFilters="sendFilters()" @closeSideBar="closeSideBar()"/>

          <b-button class="m-2" variant="outline-secondary" @click="clearFilters()">
            <b-icon icon="trash" class="cursor-pointer"/>
            {{$t('search.reset_filters')}}
          </b-button>
        </b-col>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BCard, 
  BRow, 
  BCol, 
  BFormGroup, 
  BFormSelect, 
  BButton, 
  BSpinner, 
  BSidebar, 
  BDropdown, 
  BDropdownHeader, 
  BLink, 
  BIcon, 
  BFormCheckbox, 
  VBTooltip,
  BTooltip,
} from 'bootstrap-vue'
import service from '@/services/search'
import config from '@/services/config'
import { getFiltersUsedLabel, getFormat, numberFormat, trimText, separatebycomma } from '@/libs/utils/formats'

export default {
  name: 'FilterTiktok',
  components: {
    BIcon,
    BSidebar,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BButton,
    BSpinner,
    BDropdown,
    BDropdownHeader,
    BLink,
    BTooltip,
    BFormCheckbox,
    OptionsFilters: () => import('@/views/components/button-group/OptionsFilters.vue'),
    AudienceLocationFilter: () => import('@/views/buscador/filters/audience_locations.vue'),
    FollowersFilter: () => import('@/views/buscador/filters/followers.vue'),
    buttonSubmitFilters: () => import('./buttonSubmitFilters.vue'),
    sliderRange: () => import('./sliderRange.vue'),
    ChatContact: () => import('@/views/apps/chat/ChatContact.vue'),
    applyFilters: () => import('./filters/applyFilters.vue'),
    SearchBy: () => import('@/views/components/inputs/SearchBy.vue'),
    viewAvgSlider: () => import('./viewAvgSlider.vue'),
    selectNetworkSearch: () => import('./filters/components/selectNetworkSearch.vue'),
    searchByActions: () => import('@/views/buscador/filters/components/searchByActions.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    initial_filters: {
      type: Object,
      default: () => {}
    },
    networks_avaibles: {
      type: Array,
      default: () => {
        return []
      }
    },
    select_network: {
      type: Object,
      default: () => {
        return {}
      }
    },
    filters_history: {
      type: Object,
      default: () => {}
    },
    main_locations_filter: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      getFormat,
      vue_autosuggest: null,
      vue_slider: null,
      update_mention: false,
      update_info_contact: false,
      update_aqs: false,
      update_is_verified: false,
      update_influencer_gender: false,
      update_dropdown_categories: false,
      update_followers_filter: false,
      update_gender: false,
      update_location: false,
      open_sidebar: false,
      loading: false,
      total_results: 0,
      formatter: v => `${numberFormat(v)}`,
      formatter_views: v => `${getFormat(v)}`,
      input_locations: {
        class: 'form-control',
        placeholder: this.$t("search.typeLocation")
      },
      cancel_influencer_location: null,
      influencer_gender: null,
      influencer_category: [],
      influencer_locations: [],
      influencer_location: '',
      selected_influencer_location: null,
      influencer_language: null,
      is_verified: null,
      has_contacts: null,
      quality_audience: null,
      percentage_audience_location: 10,
      audience_gender: null,
      percentage_gender: 55,
      audience_ages: [10, 65],
      percentage_audience_age: 10,
      engagement_rate: 0,
      followers_days: null,
      percentage_followers_growth: 50,
      increase_likes: null,
      percentage_increase_likes: 50,
      total_likes: [0, 10000000],
      average_likes: 0,
      views: [0, 10000000],
      average_comments: 0,
      average_shares: 0,
      most_recent: 0,
      prices: [0, 1000000],
      role: 'brand',
      loading_location_influencer: false,
      no_locations_influencer: false,
      categories: [],
      category: null,
      axios: null,
      typing_timer: null,
      cancel_search_ws: null,
      audience_locations: [{}],
      audience_locations_type: [],
      audience_locations_id: [],
      audience_locations_percentage: [],
      text_audience_location: this.$t('search.realty.any'),
      change_audience_location: false,
      text_followers: this.$t('search.realty.any'),
      followers_from: null,
      followers_to: null,
      filters: [
        {
          filter: 'audience_locations',
          text: this.$t("search.audienceLocation"),
          models: [
            {name: 'audience_locations', value: null, default: [{}]},
            {name: 'text_audience_location', value: null, default: this.$t('search.realty.any')}
          ],
          update_component: 'update_location',
          value: null,
          multiple_values: true,
          syntax: [
            {
              type: 'var',
              name: 'name',
            },
            {
              type: 'string',
              name: ' > ',
            },
            {
              type: 'var',
              name: 'percentage'
            },
            {
              type: 'string',
              name: '%',
            },
          ]
        },
        {
          filter: 'audience_ages',
          text: this.$t("search.audienceAge"),
          models: [
            {name: 'audience_ages', value: null, default: [10, 65]},
            {name: 'percentage_audience_age', value: null, default: 10},
          ],
          value: null,
        },
        {
          filter: 'audience_gender',
          text: this.$t("search.audienceGender"),
          models: [
            {name: 'audience_gender', value: null, default: null},
            {name: 'percentage_gender', value: null, default: 55}
          ],
          update_component: 'update_gender',
          value: null,
        },
        {
          filter: 'quality_audience',
          text: this.$t("search.pointAudience"),
          models: [{name: 'quality_audience', value: null, default: null}],
          update_component: 'update_aqs',
          value: null,
        },
        {
          filter: 'audience_size',
          text: this.$t("search.audienceSize"),
          models: [
            {name: 'followers_from', value: null, default: null},
            {name: 'followers_to', value: null, default: null},
            {name: 'text_followers', value: null, default: this.$t('search.realty.any')},
          ],
          update_component: 'update_followers_filter',
          value: null,
        },
        {
          filter: 'influencer_gender',
          text: this.$t("search.influencerGender"),
          models: [{name: 'influencer_gender', value: null, default: null}],
          update_component: 'update_influencer_gender',
          value: null,
        },
        {
          filter: 'influencer_category',
          text: this.$t("search.category"),
          models: [{name: 'influencer_category', value: null, default: []}],
          value: null,
          update_component: 'update_dropdown_categories',
          multiple_values: true,
          syntax: [
            {
              type: 'var',
              name: 'title',
            },
          ],
        },
        {
          filter: 'influencer_location',
          text: this.$t("search.influencerLocation"),
          models: [
            {name: 'influencer_location', value: null, default: ''},
            {name: 'selected_influencer_location', value: null, default: null}
          ],
          value: null,
        },
        {
          filter: 'influencer_language',
          text: this.$t("search.influencerLanguage"),
          models: [{name: 'influencer_language', value: null, default: null}],
          value: null,
        },
        {
          filter: 'is_verified',
          text: this.$t("search.onlyveri"),
          models: [{name: 'is_verified', value: null, default: null}],
          update_component: 'update_is_verified',
          value: null,
        },
        {
          filter: 'info_contact',
          text: this.$t("search.contact"),
          models: [{name: 'has_contacts', value: null, default: null}],
          update_component: 'update_info_contact',
          value: null,
        },
        {
          filter: 'prices',
          text: this.$t("search.priceInfluencer"),
          models: [{name: 'prices', value: null, default: [0, 1000000]},],
          value: null,
        },
        {
          filter: 'engagement_rate',
          text: this.$t("search.engagementRate"),
          models: [{name: 'engagement_rate', value: null, default: 0},],
          value: null,
        },
        {
          filter: 'follower_days',
          text: this.$t("search.increaseFollowers"),
          models: [
            {name: 'followers_days', value: null, default: null},
            {name: 'percentage_followers_growth', value: null, default: 50}
          ],
          value: null,
        },
        {
          filter: 'increase_likes',
          text: this.$t("search.increaseLikes"),
          models: [
            {name: 'increase_likes', value: null, default: null},
            {name: 'percentage_increase_likes', value: null, default: 50}
          ],
          value: null,
        },
        {
          filter: 'total_likes',
          text: this.$t("search.totalLikes"),
          models: [{name: 'total_likes', value: null, default: [0, 10000000]},],
          value: null,
        },
        {
          filter: 'average_likes',
          text: this.$t("search.averageLikes"),
          models: [{name: 'average_likes', value: null, default: 0}],
          value: null,
        },
        {
          filter: 'average_comments',
          text: this.$t("search.averageCommentsTiktok"),
          models: [{name: 'average_comments', value: null, default: 0}],
          value: null,
        },
        {
          filter: 'average_shares',
          text: this.$t("search.averageShares"),
          models: [{name: 'average_shares', value: null, default: 0}],
          value: null,
        },
        {
          filter: 'most_recent',
          text: this.$t("search.mostRecent"),
          models: [{name: 'most_recent', value: null, default: 0}],
          value: null,
        },
        {
          filter: 'views',
          text: this.$t("search.averageViewsTiktok"),
          models: [{name: 'views', value: null, default: [0, 10000000]}],
          value: null,
          update_component: 'update_view_avg_slider'
        },
        {
          filter: 'mentioned_any',
          text: `${this.$t('optionsMencionado')[1].text}: ${this.$t('search.realty.any')}`,
          models: [{name: 'mentioned_any', value: null, default: false}],
          update_component: 'update_mention',
          value: null,
        },
        {
          filter: 'mention',
          text: this.$t("search.account"),
          models: [
            {name: 'mention', value: null, default: 0},
            {name: 'brand', value: null, default: ''}
          ],
          value: null,
        },
        {
          filter: 'similar',
          text: this.$t("search.similar"),
          models: [
            {name: 'similar', value: null, default: ''},
            {name: 'selected_similar', value: null, default: null}
          ],
          value: null,
          multiple_values: false
        },
        {
          filter: 'search_by_influencer',
          text: 'anywhere',
          models: [{name: 'category', value: null, default: ''}],
          update_component: 'update_search_by',
          value: null,
          multiple_values: false
        },
      ],
      similar: '',
      similars: [],
      selected_similar: null,
      loading_similars: false,
      cancel_suggests: null,
      no_similars: false,
      searched: true,
      mentioned_any: false,
      mention: 0,
      brands: [],
      brand: '',
      loading_brands: false,
      no_brands: false,
      input_brands: {
        class: 'form-control',
        placeholder: this.$t("search.typeUser")
      },
      change_language: false,
      keywords: [],
      where: 'anywhere',
      update_search_by: false,
      network: 'tiktok',
      loaded_services_history: true,
      update_view_avg_slider: false,
      total_categories_service: null
    }
  },
  created () {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.role = userData.groups[0].name
    this.getCategories()

    this.searched = true
  },
  async mounted() {
    this.axios = (await import('axios')).default;
    this.vue_slider = (await import('vue-slider-component')).default;
    this.vue_autosuggest = (await import('vue-autosuggest')).VueAutosuggest
    setTimeout(() => {
      if (this.initial_filters && Object.keys(this.initial_filters).length > 0) {
        this.initialSearch()
      } else if (Object.keys(this.filters_history).length === 0) {
        this.getCounter()
      }
    }, 200);
  },
  computed: {
  },
  methods: {
    updateSelectNetwork(select_network) {
      this.$emit('update_select_network', select_network)
    },
    initialSearch() {
      const filters = {}
      let name_country = '';
      Object.keys(this.initial_filters).forEach((key) => {
        if (key !== 'st') {
          if (key === 'audience_location') {
            filters['audience_location_id'] = [this.initial_filters[key][0].id]
            filters['audience_location_type'] = [this.initial_filters[key][0].type]
            filters['audience_location_from'] = [this.initial_filters[key][0].prc]
            name_country = this.initial_filters[key][0].name
          } else if (key === 'followers_growth') {
            filters['followers_growth_period'] = parseInt(this.initial_filters[key][0].id)
            filters['followers_growth_to'] = this.initial_filters[key][0].prc
          } else if (key === 'influencer_category') {
            filters['influencer_tt_category'] = [parseInt(this.initial_filters[key][0].id.replace('3_', ''))]
          } else if (key === 'tt_similar') {
            filters[key] = this.initial_filters[key]
            if (this.initial_filters.provisional_username_label) {
              setTimeout(() => {
                filters['provisional_username_label'] = this.initial_filters.provisional_username_label;
              }, 20);
            }
          } else if (key === 'sort_order') {
            filters[key] = this.initial_filters[key]
          } else if (key === 'sort_type') {
            filters[key] = this.initial_filters[key]
          } else {
            filters[key] = this.initial_filters[key][0].id
          }
        } else {
          filters['st'] = 'tt'
        }
      })

      this.formatApplyFilters(filters, name_country)
      this.$emit('clean_selected');
      this.searched = true

      this.$emit('callTiktok', filters, true)
      this.getCounter(filters)
    },
    formatFiltersHistory(filters) {
      this.loaded_services_history = false;
      this.formatApplyFilters(filters, '')
      this.$emit('callTiktok', filters, true)
      this.getCounter(filters)
    },
    formatApplyFilters(filters, name_country) {
      Object.keys(filters).forEach(key => {
        if (key === 'aqs') {
          const filter = this.filters.find(f => f.filter === 'quality_audience')
          filter.models[0].value = filters[key]
          filter.value = filters[key]

          this.quality_audience = filters[key];
          const value_aqs = this.$t('qualityAudience').find(i => i.value === filters[key]);
          const key_index_aqs = this.$t('qualityAudience').findIndex((i) => i.value === filters[key])
          if (this.$refs.options_filters_gender) {
            this.$refs.options_filters_gender.valueOptions(value_aqs, key_index_aqs) 
            this.update_aqs = !this.update_aqs;
          }
        } else if (key === 'audience_gender_id') {
          const gender_text = this.$t('instaGen').find(g => g.value === filters['audience_gender_id'])

          this.audience_gender = this.$t('instaGen').find((i) => i.value === filters['audience_gender_id'])
          const percentage_gender = filters[key]
          this.percentage_gender = percentage_gender;
          const new_models = [{name: 'audience_gender', value: filters['audience_gender_id']}]
          this.changeFilter('audience_gender', new_models, `${gender_text.text}, ≥${percentage_gender}%`)
          this.changeFilter('audience_gender', [], `${gender_text.text}, ≥${percentage_gender}%`)
          
          const key_index = this.$t('instaGen').findIndex((i) => i.value === this.audience_gender.value)
          this.setAudienceGender(this.audience_gender, key_index)
          this.update_gender = !this.update_gender;
        } else if (key === 'audience_age_from') {
          this.percentage_audience_age = filters['audience_age_p']
          this.audience_ages = [filters['audience_age_from'] ? filters['audience_age_from'] : 10, filters['audience_age_to'] ? filters['audience_age_to'] : 65];
          const new_models = [{name: 'audience_ages', value: filters['audience_age_p']}]
          this.changeFilter('audience_ages', new_models, this.getAudienceAge())
        } else if (key === 'audience_location_id') {
          const filter = this.filters.find(f => f.filter === 'audience_locations')
          filter.value = `${name_country} ≥ ${filters['audience_location_from']}`

          this.text_audience_location = filters['audience_location_text'] ? filters['audience_location_text'] : this.$t('search.realty.any')
          const country = this.text_audience_location.split(',').slice(0, -1)
          filter.models[0].value = [{id: filter[key], name: name_country.length === 0 ? country.join(', ') : name_country, percentage: filters['audience_location_from'], type: filters['audience_location_type']}]
        } else if (key === 'followers_growth_to') {
          const filter = this.filters.find(f => f.filter === 'follower_days')
          const period = parseInt(filters['followers_growth_period'])
          filter.models[0].value = period
          filter.models[1].value = filters[key]
          this.followers_days = filters['followers_growth_period'];
          this.percentage_followers_growth = filters['followers_growth_to']
          filter.value = `${this.$t('days').find((item) => item.value === period).text}, ${filters[key]}%`
        } else if (key === 'influencer_tt_category') {

          if (this.categories.length === 0) {
            setTimeout(() => {
              const cat = this.categories.find((item) => item.id === filters[key][0])
              this.influencer_category.push(cat);
              const new_models = [{name: 'influencer_category', value: this.influencer_category}];
              this.changeFilter('influencer_category', new_models, this.influencer_category.length !== 0 ? this.influencer_category[0].title : null);
            }, 1000)
          } else {
            const cat = this.categories.find((item) => item.id === filters[key][0])
            this.influencer_category.push(cat);
            const new_models = [{name: 'influencer_category', value: this.influencer_category}];
            this.changeFilter('influencer_category', new_models, this.influencer_category.length !== 0 ? this.influencer_category[0].title : null);
          }
        } else if (key === 'keywords') {
          filters[key].forEach((i, index) => {
            this.filters.push(
              {
                filter: `search_by_keywords_${index}`,
                text: this.$t(`search.${i.type_keyword}`),
                models: [{name: 'keywords', value: 'fdsa', default: ''}],
                value: i.keyword,
                multiple_values: false
              },
            )            
          })
        } else if (key === 'tt_similar') {
          const filter = this.filters.find((f) => f.filter === 'similar')
          filter.models[0].value = filters[key]
          filter.models[1].value = filters[key]
          setTimeout(() => {
            filter.value = filters['provisional_username_label'] ? filters['provisional_username_label'] : filters[key];
          }, 20);
          this.selected_similar = filters[key];
          this.similar = filters[key]
        } else if (key === 'followers_from') {
          const filter = this.filters.find(f => f.filter === 'audience_size')
          filter.models[0].value = filters[key]
          filter.models[1].value = filters['followers_to']
          filter.value = `${getFormat(filters[key])}-${getFormat(filters['followers_to'])}`

          this.followers_from = filters[key]
          this.followers_to = filters['followers_to']
          this.update_followers_filter = !this.update_followers_filter

          if (this.followers_from && this.followers_to) {
            this.text_followers = `${getFormat(this.followers_from)}-${getFormat(this.followers_to)}`
          } else if (this.followers_from) {
            this.text_followers = `≥${getFormat(this.followers_size[0])}`
          } else if (this.followers_to) {
            this.text_followers = `≤${getFormat(this.followers_size[1])}`
          }
        } else if (key === 'influencer_gender' || key === 'influencer_language' || key === 'is_verified' || key === 'has_contacts') {
          const key_filter = key !== 'has_contacts' ? key : 'info_contact';
          const filter = this.filters.find((f) => f.filter === key_filter)
          filter.models[0].value = filters[key]
          filter.value = filters[key]
          this[key] = filters[key];
        } else if (key === 'influencer_location_id') {
          const filter = this.filters.find(f => f.filter === 'influencer_location')
          filter.models[0].value = filters['influencer_location_text'];
          filter.value = filters['influencer_location_text']
          this.influencer_location_id = filters['influencer_location_id'];
          this.influencer_location_type = filters['influencer_location_type']
          this.influencer_location = filters['influencer_location_text'];
        } else if (key === 'blogger_prices_from' || key === 'blogger_prices_to') {
          const filter = this.filters.find(f => f.filter === 'prices')
          filter.models[0].value = [
            filters['blogger_prices_from'] ? filters['blogger_prices_to'] : filter.models[0].default[0],
            filters['blogger_prices_to'] ? filters['blogger_prices_to'] : filter.models[0].default[1]
          ];
          this.prices = [
            filters['blogger_prices_from'] ? filters['blogger_prices_from'] : filter.models[0].default[0],
            filters['blogger_prices_to'] ? filters['blogger_prices_to'] : filter.models[0].default[1]
          ]
        } else if (key === 'er') {
          this.engagement_rate = filters[key]
        } else if (key === 'avg_likes_from') {
          this.average_likes = filters[key]
        } else if (key === 'tt_comments_from') {
          this.average_comments = filters[key]
        } else if (key === 'tt_shares_from') {
          this.average_shares = filters[key]
        } else if (key === 'yt_recent_video_to') {
          this.most_recent = filters[key]
        } else if (key === 'tt_likes_growth_to') {
          const filter = this.filters.find(f => f.filter === 'increase_likes')
          const period = parseInt(filters['tt_likes_growth_period'])
          filter.models[0].value = period
          filter.models[1].value = filters[key]
          this.increase_likes = filters['tt_likes_growth_period'];
          this.percentage_increase_likes = filters['tt_likes_growth_to']
          filter.value = `${this.$t('days').find((item) => item.value === period).text}, ${filters[key]}%`
        } else if (key === 'likes_to' || key === 'likes_from') {
          const filter = this.filters.find(f => f.filter === 'total_likes')
          filter.models[0].value = [
            filters['likes_from'] ? filters['likes_from'] : filter.models[0].default[0],
            filters['likes_to'] ? filters['likes_to'] : filter.models[0].default[1]
          ];
          this.total_likes = [
            filters['likes_from'] ? filters['likes_from'] : filter.models[0].default[0],
            filters['likes_to'] ? filters['likes_to'] : filter.models[0].default[1]
          ]
        } else if (key === 'tt_views_from' || key === 'tt_views_to') {
          const filter = this.filters.find(f => f.filter === 'views')
          filter.models[0].value = [
            filters['tt_views_from'] ? filters['tt_views_from'] : filter.models[0].default[0],
            filters['tt_views_to'] ? filters['tt_views_to'] : filter.models[0].default[1]
          ];
          this.views = [
            filters['tt_views_from'] ? filters['tt_views_from'] : filter.models[0].default[0],
            filters['tt_views_to'] ? filters['tt_views_to'] : filter.models[0].default[1]
          ]

          const view_avg_slider_component = this.$refs.view_avg_slider
          if (view_avg_slider_component) {
            view_avg_slider_component.range = this.views;
          }
        } else if (key === 'mentioned_any') {
          this[key] = filters[key]
        } else if (key === 'brands') {
          const filter = this.filters.find(f => f.filter === 'mention')
          filter.models[0].value = filters['mentioned_name'] ? filters['mentioned_name'] : ''
          filter.models[1].value = filters['mentioned_name'] ? filters['mentioned_name'] : ''
          filter.value = filters['mentioned_name'] ? filters['mentioned_name'] : ''
        } 
      })
    },
    getSimilarValue(suggestion) {
      this.similar = suggestion.item.username
      this.selected_similar = suggestion.item
      const new_models = [{name: 'similar', value: this.similar}]
      this.changeFilter('similar', new_models, this.similar)
      this.getCounter()
    },
    inputSimilar(text) {
      this.similars = [];
      this.no_similars = false;
      if (text !== '') {
        this.loading_similars = true;
        const CancelToken = this.axios.CancelToken;

        if (this.cancel_suggests) this.cancel_suggests()

        const self = this;
        this.axios({
          method: 'post',
          url: `${config.urlCore}/api/v1/finder/suggester/`,
          data: {
            query: trimText(text),
            networks: 'tiktok',
          },
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          },
          cancelToken: new CancelToken(function executor(c) {
            self.cancel_suggests = c;
          })
        })
          .then(res => {
            this.no_similars = res.data.response.list.length < 1;
            this.loading_similars = false;
            this.similars = [{data: res.data.response.list}]
          })
          .catch(error => {
            if (this.axios.isCancel(error)) {
              console.log('');
            } else {
              this.no_similars = true;
              this.loading_similars = false;
            }
          });
      }
    },
    getValueInput(suggestion) {
      return suggestion.item.username
    },
    filterQualityAudience(options) {
      this.quality_audience = options.value
    },
    filterGender(options) {
      this.influencer_gender = options.value
    },
    filterVerified(options) {
      this.is_verified = options.value
    },
    filterHasContacts(options) {
      this.has_contacts = options.value
    },
    filterMentionedAny(options) {
      this.mentioned_any = options.value
    },
    addKeywordFilter(new_models, keywords, where = {}) {
      if (where.category) { 
        this.keywords = [];
        this.filters = this.filters.filter((i) => !i.filter.includes('search_by_keywords'))
        this.$refs.search_by_actions.$refs.search_by_category.keywords_array = [];
        this.category = keywords;

        if (keywords.length === 0) {
          new_models[0].value = null;
        } else {
          const filter = this.filters.find(i => i.filter === 'search_by_influencer')
          filter.text = this.$t('search.category');
          this.changeFilter('search_by_influencer', new_models, keywords);
        }
  
        clearTimeout(this.typing_timer);
        this.typing_timer = setTimeout(this.getCounterInputHandleIt, 500);
      } else {
        this.keywords = keywords;
        this.category = null;
        this.getCounter();
        
        if (where.tag_deleted !== null) this.filters = this.filters.filter((i) => i.value !== where.tag_deleted);
        if (where.deleted_all) this.filters = this.filters.filter((i) => !i.filter.includes('search_by_keywords'))
        keywords.forEach((i, index) => {
          if (!this.filters.find(j => j.value === i.keyword)) {
            this.filters.push(
              {
                filter: `search_by_keywords_${index}`,
                text: this.$t(`search.${i.type_keyword}`),
                models: [{name: 'keywords', value: null, default: ''}],
                value: null,
                multiple_values: false
              },
            )
            this.changeFilter(`search_by_keywords_${index}`, new_models, i.keyword);
          }
        })
      }
    },
    getCounterInputHandleIt(prev_filters = false) {
      this.searched = false
      const filters = prev_filters ? prev_filters : this.getFilters()
      filters.counter = 1;

      service.saveLogSearch(filters)
        .then(() => {
        })
      this.loading = true;
      this.total_results = 0

      const CancelToken = this.axios.CancelToken;
      if (this.cancel_search_ws) this.cancel_search_ws()
      const self = this;
      this.axios({
        method: 'post',
        url: `${config.urlPhp}/api/v1/buscador/influencers`,
        headers: { 'brandme-key': process.env.VUE_APP_BRANDME_KEY },
        data:{
          ...filters
        },
        cancelToken: new CancelToken(function executor(c) {
          self.cancel_search_ws = c;
        })
      }).then((response) => {
        this.total_results = response.data.data.total ? response.data.data.total : 0
        this.loading = false;
      })
    },
    changeInfluencerCategory(category, id_deleted) {
      const new_models = [{name: 'influencer_category', value: category}];
      this.$refs[`word_item_category_${id_deleted}`][0].classList.remove('word-break');
      this.$refs[`check_item_category_${id_deleted}`][0].classList.add('d-none');
      this.changeFilter('influencer_category', new_models, null);
      this.getCounter();
    },
    addCategoryFilter(category) {
      if (this.influencer_category.find((item) => item.id === category.id) === undefined) {
        this.influencer_category.push(category);
        const new_models = [{name: 'influencer_category', value: this.influencer_category}];
        this.changeFilter('influencer_category', new_models, this.influencer_category.length !== 0 ? this.influencer_category[0].title : null);
        this.getCounter();
        this.$refs[`word_item_category_${category.id}`][0].classList.add('word-break');
        this.$refs[`check_item_category_${category.id}`][0].classList.remove('d-none');
      } else {
        this.influencer_category.forEach((item,i) => { if (item.id === category.id) this.influencer_category.splice(i,1) })
        this.$refs[`word_item_category_${category.id}`][0].classList.remove('word-break');
        this.$refs[`check_item_category_${category.id}`][0].classList.add('d-none');
        this.getCounter();
      }     
    },
    deleteFilter(name, get_counter = true) {
      const to_deleted = this.filters.find((item) => item.filter === name);
      if (name.includes('search_by_keywords')) {
        this.$refs.search_by_actions.$refs.search_by_category.keywords_array = this.$refs.search_by_actions.$refs.search_by_category.keywords_array.filter(i => i.keyword !== to_deleted.value)
        this.keywords = this.keywords.filter(i => i.keyword !== to_deleted.value)
      }

      to_deleted.models.forEach((item) => {
        if (!name.includes('search_by_keywords')) this[item.name] = item.default;
        if (item.name === 'influencer_category') item.default = []
        item.value = null;
      });
      if (to_deleted.update_component) this[to_deleted.update_component] = !this[to_deleted.update_component];
      to_deleted.value = null;
      this.closeCollapse(to_deleted.filter);
      if (get_counter) this.getCounter();
      this.searched = true

      if (name.includes('search_by_keywords')) this.filters = this.filters.filter(i => i.filter !== name)
    },
    closeCollapse(id) {
      if (this.$refs[`collapse_filters_${id}`]) {
        this.$refs[`collapse_filters_${id}`].classList.contains('d-none') ? '' : this.$refs[`collapse_filters_${id}`].classList.add('d-none');
        this.$refs[`chev_up_${id}`].classList.add('d-none');
        this.$refs[`chev_down_${id}`].classList.contains('d-none') ? this.$refs[`chev_down_${id}`].classList.remove('d-none') : '';
      }
    },
    checkFiltersUsed() {
      return this.filters.filter(item => item.models[0].value !== null);
    },
    clearFilters(loading_results = true, send_service = true) {
      this.filters.forEach(item => {
        this.deleteFilter(item.filter, false)
      })
      if (send_service) {
        this.sendFilters(loading_results);
        this.getCounter();
      }

      this.searched = true
      this.open_sidebar = false;
    },
    toggleCollapse(id) {
      if (this.$refs[`collapse_filters_${id}`]) {
        this.$refs[`collapse_filters_${id}`].classList.toggle('d-none');
        this.$refs[`chev_down_${id}`].classList.toggle('d-none');
        this.$refs[`chev_up_${id}`].classList.toggle('d-none');
      }
    },
    getAudienceAge() {
      const paragraph = `, ≥ ${this.percentage_audience_age}%`;
      let first = '';
      if (this.audience_ages[1] === 65) first = `≥ ${this.audience_ages[0]}`
      else if (this.audience_ages[0] === 10)  first = `≤ ${this.audience_ages[1]}`
      else first = `${this.audience_ages[0]} - ${this.audience_ages[1]}`;
      return first + paragraph;
    },
    setAudienceGender(gender, index) {
      this.audience_gender = gender;
      this.$refs.gender_dropdown.map((item, i) => {
        if (i === index) item.classList.add('background-clicked-form');
        else item.classList.remove('background-clicked-form');
      });
    },
    getCategories() {
      service.getCategories('tiktok')
        .then(response => {
          this.total_categories_service = response;
          response.categories.forEach(cat => {
            const category = {
              id: cat.id,
              title: this.$t(`filters.${cat.title}`)
            }
            this.categories.push(category)
          });
          this.categories.sort((a, b) => (a.title < b.title ? -1 : 1));
        })
        .catch(() => {
        })
    },
    getText() {
      if (this.loading) return this.$t("search.searching")
      if (this.total_results > 0 && this.searched) return `${getFormat(this.total_results)} ${this.$t("search.results")}`
      if (!this.searched) return `${getFormat(this.total_results)} ${this.$t("search.showResults")}`
      return this.$t('search.noResults')
    },
    getRangeAgesAudience() {
      if (this.audience_ages[0] !== 10 && this.audience_ages[1] !== 65) {
        return {
          'from': this.audience_ages[0],
          'to': this.audience_ages[1]
        }
      } else if (this.audience_ages[0] > 10 && this.audience_ages[1] === 65) {
        return {
          'from': this.audience_ages[0],
          'to': null
        }
      } else if (this.audience_ages[0] === 10 && this.audience_ages[1] < 65) {
        return {
          'from': null,
          'to': this.audience_ages[1]
        }
      }

      return {
        'from': null,
        'to': null,
      }
    },
    getRange(quantity) {
      if (quantity[0] !== 0 && quantity[1] !== 10000000) {
        return {
          'from': quantity[0],
          'to': quantity[1]
        }
      } else if (quantity[0] > 0 && quantity[1] === 10000000) {
        return {
          'from': quantity[0],
          'to': null
        }
      } else if (quantity[0] === 0 && quantity[1] < 10000000) {
        return {
          'from': null,
          'to': quantity[1]
        }
      }

      return {
        'from': null,
        'to': null,
      }
    },
    getRangePrice() {
      if (this.prices[0] !== 0 && this.prices[1] !== 1000000) {
        return {
          'from': this.prices[0],
          'to': this.prices[1]
        }
      } else if (this.prices[0] > 0 && this.prices[1] === 1000000) {
        return {
          'from': this.prices[0],
          'to': null
        }
      } else if (this.prices[0] === 0 && this.prices[1] < 1000000) {
        return {
          'from': null,
          'to': this.prices[1]
        }
      }

      return {
        'from': null,
        'to': null,
      }
    },
    getCounter (prev_filters = false) {
      this.searched = false
      const filters = prev_filters ? prev_filters : this.getFilters()
      filters.counter = 1;

      service.saveLogSearch(filters)
        .then(() => {
        })

      this.loading = true;
      this.total_results = 0
      service.getResults(filters)
        .then(response => {
          if (Object.keys(this.filters_history).length > 0) this.loaded_services_history = true;
          this.total_results = response.data.total
          this.loading = false;
        }).catch(() => {
          this.total_results = 0
          this.loading = false;
        })
    },
    addAudienceLocation() {
      this.audience_locations.push({})
    },
    changeFollowers(data) {
      this.followers_from = data.from;
      this.followers_to = data.to;
      let value_model = data.from + data.to
      if (this.followers_from && this.followers_to) {
        this.text_followers = `${getFormat(this.followers_from)}-${getFormat(this.followers_to)}`
      } else if (this.followers_from) {
        this.text_followers = `≥${getFormat(this.followers_from)}`
      } else if (this.followers_to) {
        this.text_followers = `≤${getFormat(this.followers_to)}`
      } else {
        value_model = null
        this.text_followers = this.$t('search.realty.any')
      }
      const new_models = [
        {name: 'followers_from', value: value_model},
        {name: 'followers_to', value: this.followers_to},
      ]
      this.changeFilter('audience_size', new_models, this.text_followers)
      this.getCounter()
    },
    removeAudienceLocation(location, index, force_remove = false, from_filters = false) {
      const self = this
      setTimeout(function() {
        const is_show = self.$refs.filter_audience_location.$el.querySelector('ul').classList.contains('show')
        if (!from_filters && !is_show && force_remove) self.$refs.filter_audience_location.$el.querySelector('button').click()
      }, 5)

      if (force_remove) this.audience_locations.splice(index, 1)
      else this.audience_locations[index] = {}

      if (!location) return

      if (this.audience_locations.length === 0) {
        this.deleteFilter('audience_locations')
        this.text_audience_location = this.$t('search.realty.any')
      } else {
        let counter_empty = 0;
        const location_length = this.audience_locations.filter((item) => item.id).length;
        this.audience_locations.map((item) => {
          if (item.id) {
            const additional_text = location_length > 1 ? `+${location_length - 1}` : '';
            this.text_audience_location = `${item.name}, ≥${item.percentage}% ${additional_text}`;
          } else {
            counter_empty += 1;
          }
          if (this.audience_locations.length === counter_empty) {
            this.deleteFilter('audience_locations')
            this.text_audience_location = this.$t('search.realty.any');
          }
        });
      }

      this.update_location = !this.update_location

      this.getCounter()
    },
    changeLocationAudience(data) {
      const index = data.key
      this.audience_locations[index].id = data.location.id
      this.audience_locations[index].type = data.location.type
      this.audience_locations[index].name = data.location.name
      this.audience_locations[index].percentage = data.percentage

      const location_length = this.audience_locations.filter((item) => item.id).length;

      this.audience_locations.map((item) => {
        if (item.id) {
          const additional_text = location_length > 1 ? `+${location_length - 1}` : '';
          this.text_audience_location = `${item.name}, ≥${item.percentage}% ${additional_text}`;
        }
      });
      const new_models = [{name: 'audience_locations', value: this.audience_locations}]
      this.changeFilter('audience_locations', new_models, this.text_audience_location)
      this.getCounter()
    },
    formatAudienceLocations() {
      this.audience_locations_id = []
      this.audience_locations_type = []
      this.audience_locations_percentage = []
      if (this.audience_locations.length > 0) {
        this.audience_locations.forEach(location => {
          if (location.id) {
            this.audience_locations_id.push(location.id)
            this.audience_locations_type.push(location.type)
            this.audience_locations_percentage.push(location.percentage)
          }
        })
      }
    },
    getFilters () {
      const finalFilters = {}
      const audience_ages = this.getRangeAgesAudience()
      const likes = this.getRange(this.total_likes)
      const views = this.getRange(this.views)
      const prices = this.getRangePrice()
      this.formatAudienceLocations()
      
      const data = {
        hash: localStorage.getItem('accessToken'),
        st: 'tt',
        keywords: this.keywords.length !== 0 ? this.keywords : null,
        where: this.where === 'anywhere' ? null : this.where,
        followers_from: this.followers_from,
        followers_to: this.followers_to,
        influencer_gender: this.influencer_gender,
        // influencer_tt_category: this.influencer_category.length !== 0 ? this.influencer_category.map((item) => item.id) : null,
        influencer_tt_category: this.category ? [this.categories.find(i => i.title === this.category).id] : null,
        influencer_location_id: this.selected_influencer_location ? [this.selected_influencer_location.id] : null,
        influencer_location_type : this.selected_influencer_location ? [this.selected_influencer_location.type] : null,
        influencer_location_text: this.influencer_location ? this.influencer_location : null,
        influencer_language: this.influencer_language,
        is_verified: this.is_verified,
        has_contacts: this.has_contacts,
        aqs: this.quality_audience,
        audience_location_id: this.audience_locations_id.length > 0 ? this.audience_locations_id : null,
        audience_location_type: this.audience_locations_type.length > 0 ? this.audience_locations_type : null,
        audience_location_from: this.audience_locations_percentage.length > 0 ? this.audience_locations_percentage : null,
        audience_location_text: this.text_audience_location !== this.$t('search.realty.any') ? this.text_audience_location : null,
        audience_gender_id: this.audience_gender ? this.audience_gender.value : null,
        audience_gender_from: this.audience_gender ? this.percentage_gender : null,
        audience_age_from: audience_ages.from,
        audience_age_to: audience_ages.to,
        audience_age_p: this.showPercentageAgeAudience() ? this.percentage_audience_age : null,
        er: this.engagement_rate > 0 ? this.engagement_rate : null,
        followers_growth_period: this.followers_days,
        followers_growth_to: this.followers_days ? this.percentage_followers_growth : null,
        tt_likes_growth_period: this.increase_likes,
        tt_likes_growth_to: this.increase_likes ? this.percentage_increase_likes : null,
        likes_from: likes.from,
        likes_to: likes.to,
        avg_likes_from: this.average_likes > 0 ? this.average_likes : null,
        tt_views_from: views.from,
        tt_views_to: views.to,
        tt_comments_from: this.average_comments > 0 ? this.average_comments : null,
        tt_shares_from: this.average_shares > 0 ? this.average_shares : null,
        yt_recent_video_to: this.most_recent > 0 ? this.most_recent : null,
        blogger_prices_from: prices.from,
        blogger_prices_to: prices.to,
        mentioned_any: this.mentioned_any ? this.mentioned_any : null,
        mentioned_name: this.brand ? this.brand : null,
        brands: !this.mentioned_any && this.selected_brand ? [this.selected_brand.user_id] : null,
        not_mentioned: !this.mentioned_any && this.selected_brand ? this.mention : null,
        total_results: this.total_results,
        filters_label_used: getFiltersUsedLabel(this.filters),
        tt_similar: this.selected_similar ? this.selected_similar.user_id : null,
      }
      const dataKeys = Object.keys(data)
      for (let i = 0; i < dataKeys.length; i++) {
        const dataKey = dataKeys[i]
        if (data[dataKey] !== null) {
          finalFilters[dataKey] = data[dataKey]
        }
      }

      return finalFilters
    },
    sendFilters (loading_results = true) {
      this.searched = true
      const finalFilters = this.getFilters()
      this.$emit('callTiktok', finalFilters, loading_results)
      this.$emit('clean_selected');
    },
    closeSideBar() {
      this.open_sidebar = false
    },
    shouldRenderSuggestions (size, loading) {
      return size >= 0 && !loading
    },
    inputInfluencerLocation (text) {
      this.influencer_locations = []
      this.no_locations_influencer = false;
      if (text !== '') {
        this.loading_location_influencer = true;
        const CancelToken = this.axios.CancelToken;

        if (this.cancel_influencer_location) this.cancel_influencer_location()

        const self = this;
        this.axios({
          method: 'post',
          url: `${config.urlPhp}/api/v1/ubicaciones`,
          data:{
            query: text
          },
          headers: {
            'brandme-key': process.env.VUE_APP_BRANDME_KEY,
          },
          cancelToken: new CancelToken(function executor(c) {
            self.cancel_influencer_location = c;
          })
        })
          .then(result => {
            const { countries } = result.data.data
            self.datasuggest = [...countries.map(country => ({id:country.geonameid, name:country.name, type: 0}))]
            const filteredData = self.datasuggest
            self.loading_location_influencer = false;
            self.no_locations_influencer = filteredData.length < 1;
            self.influencer_locations = [{data: filteredData}]
          })
          .catch(error => {
            if (this.axios.isCancel(error)) {
              console.log('');
            } else {
              this.loading_location_influencer = false;
              this.no_locations_influencer = true;
            }
            return []
          });
      }
    },
    getInfluencerLocation (suggestion) {
      this.influencer_location = suggestion.item.name;
      this.selected_influencer_location = suggestion.item;
      const new_models = [{name: 'influencer_location', value: this.influencer_location}]
      this.changeFilter('influencer_location', new_models, this.influencer_location)
      this.getCounter()
    },
    getLabelViews() {
      if (this.views[0] === 0 && this.views[1] === 10000000) return this.$t("search.anyQuantity")
      if (this.views[0] !== 0 && this.views[1] !== 10000000) return `${this.$t("search.between")} ${numberFormat(this.views[0])} ${this.$t("search.and")} ${numberFormat(this.views[1])}`
      if (this.views[0] > 0) return `${this.$t("search.lessThan")} ${numberFormat(this.views[0])}`
      if (this.views[1] < 10000000) return `${this.$t("search.greaterThan")} ${numberFormat(this.views[1])}`

      return this.$t("search.anyQuantity")
    },
    getLabelLikes() {
      if (this.total_likes[0] === 0 && this.total_likes[1] === 10000000) return this.$t("search.anyQuantity")
      if (this.total_likes[0] !== 0 && this.total_likes[1] !== 10000000) return `${this.$t("search.between")} ${numberFormat(this.total_likes[0])} ${this.$t("search.and")} ${numberFormat(this.total_likes[1])}`
      if (this.total_likes[0] > 0) return `${this.$t("search.lessThan")} ${numberFormat(this.total_likes[0])}`
      if (this.total_likes[1] < 10000000) return `${this.$t("search.greaterThan")} ${numberFormat(this.total_likes[1])}`

      return this.$t("search.anyQuantity")
    },
    getLabelAgeAudience() {
      if (this.audience_ages[0] === 10 && this.audience_ages[1] === 65) return this.$t("search.anyAge")
      if (this.audience_ages[0] !== 10 && this.audience_ages[1] !== 65) return `${this.$t("search.between")} ${this.audience_ages[0]} ${this.$t("search.and")} ${this.audience_ages[1]}`
      if (this.audience_ages[0] > 10) return `${this.$t("search.lessThan")} ${this.audience_ages[0]}`
      if (this.audience_ages[1] < 65) return `${this.$t("search.greaterThan")} ${this.audience_ages[1]}`

      return this.$t("search.anyAge")
    },
    showPercentageAgeAudience() {
      if (this.audience_ages[0] === 10 && this.audience_ages[1] === 65) return false
      return true
    },
    changeSlideComponent(identifier, new_range) {
      this[identifier] = new_range
      this.getCounter()

      // For a strange reason this conditional shouldn´t be here, 
      // but this language is javascript and it is the only way it works!
      if (identifier === 'views') {
        const new_models = [{name: 'views', value: new_range[0] !== 0 || new_range[1] !== 10000000 ? new_range : null}]
        this.changeFilter('views', new_models, new_range[0] !== 0 || new_range[1] !== 10000000 ? this.getLabelViews() : null)
      }
      // ============================================================================================
    },
    changeSlide() {
      this.getCounter()
    },
    getLabelPrices() {
      if (this.prices[0] === 0 && this.prices[1] === 1000000) return this.$t("search.anyPrice")
      if (this.prices[0] !== 0 && this.prices[1] !== 1000000) return `${this.$t("search.between")} $${numberFormat(this.prices[0])} ${this.$t("search.and")} $${numberFormat(this.prices[1])}`
      if (this.prices[0] > 0) return `${this.$t("search.lessThan")} $${numberFormat(this.prices[0])}`
      if (this.prices[1] < 1000000) return `${this.$t("search.greaterThan")} $${numberFormat(this.prices[1])}`

      return this.$t("search.anyPrice")
    },
    inputBrands(text) {
      this.brands = []
      this.no_brands = false;
      if (text !== '') {
        this.loading_brands = true;
        const CancelToken = this.axios.CancelToken;

        if (this.cancel_suggests) this.cancel_suggests()

        const self = this;
        this.axios({
          method: 'post',
          url: `${config.urlCore}/api/v1/finder/suggester/`,
          data: {
            query: trimText(text),
            networks: 'tiktok',
          },
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          },
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            self.cancel_suggests = c;
          })
        })
          .then(res => {
            this.loading_brands = false;
            this.no_brands = res.data.response.list.length < 1;
            this.brands = [{data: res.data.response.list}]
          })
          .catch(error => {
            if (this.axios.isCancel(error)) {
              console.log('');
            } else {
              this.no_brands = true;
              this.loading_brands = false;
            }
          });
      }
    },
    getBrand(suggestion) {
      this.brand = suggestion.item.username
      this.selected_brand = suggestion.item
      this.getCounter()

      const new_models = [
        {name: 'mention', value: this.mention},
        {name: 'brand', value: this.brand}
      ]
      this.changeFilter('mention', new_models, this.brand)

      const filter = this.filters.find(item => item.filter === 'mention')
      filter.text = `${this.$t('optionsMencionado').find((item) => item.value === this.mention).text}`
    },
    changeFilter(name_filter, new_models, value_filter) {
      const filter = this.filters.find(item => item.filter === name_filter)

      filter.models.forEach(model => {
        const new_model = new_models.find(mod => mod.name === model.name)

        if (new_model) {
          model.value = new_model.value
        }
      })
      filter.value = value_filter
    },
    updateModel(data) {
      if (data.name_emit) {
        this[data.name_emit](...data.params)
      }
    }
  },
  watch: {
    loading(val) {
      this.$emit('change_loading', val);
    },
    filters_history(val, old_val) {
      this.clearFilters(true, false);
      if (val && Object.keys(val).length > 0 && (JSON.stringify(val) !== JSON.stringify(old_val))) {
        this.formatFiltersHistory(val, '')
      }
    },
    initial_filters(val) {
      if (Object.keys(val).length > 0) this.initialSearch()
    },
    '$i18n.locale'() {
      this.input_locations = {
        class: 'form-control',
        placeholder: this.$t("search.typeLocation")
      }

      if (this.audience_locations.length === 1 && !this.audience_locations[0].id) {
        this.text_audience_location = this.$t('search.realty.any')
      }
    },
    influencer_gender(val) {
      const new_models = [{name: 'influencer_gender', value: val ? this.$t('instaGen').find((item) => item.value === val).value : null}]
      this.changeFilter('influencer_gender', new_models, this.$t('instaGen').find((item) => item.value === val).text)
      if (this.loaded_services_history) this.getCounter()
    },
    influencer_language(val) {
      const new_models = [{name: 'influencer_language', value: this.$t('instaIdioma').find((item) => item.value === val).value}]
      this.changeFilter('influencer_language', new_models, this.$t('instaIdioma').find((item) => item.value === val).text)
      if (this.loaded_services_history) this.getCounter()
    },
    is_verified(val) {
      const new_models = [{name: 'is_verified', value: this.$t('instaVerificado').find((item) => item.value === val).value}]
      this.changeFilter('is_verified', new_models, this.$t('instaVerificado').find((item) => item.value === val).text)
      if (this.loaded_services_history) this.getCounter()
    },
    has_contacts(val) {
      const new_models = [{name: 'has_contacts', value: this.$t('instaContacto').find((item) => item.value === val).value}]
      this.changeFilter('info_contact', new_models, this.$t('instaContacto').find((item) => item.value === val).text)
      if (this.loaded_services_history) this.getCounter()
    },
    quality_audience(val) {
      const new_models = [{name: 'quality_audience', value: this.$t('qualityAudience').find((item) => item.value === val).value}]
      this.changeFilter('quality_audience', new_models, this.$t('qualityAudience').find((item) => item.value === val).text)
      if (this.loaded_services_history) this.getCounter()
    },
    audience_gender(val) {
      const new_models = [{name: 'audience_gender', value: val ? val.value : null}]
      if (val && val.value === null) {
        new_models.push({name: 'percentage_gender', value: 55})
        this.percentage_gender = 55;
      }
      this.changeFilter('audience_gender', new_models, val ? `${val.text}, ≥${this.percentage_gender}%` : null)
      if (this.loaded_services_history) this.getCounter()
    },
    percentage_gender(val) {
      this.changeFilter('audience_gender', [], this.audience_gender ? `${this.audience_gender.text}, ≥${val}%` : null)
    },
    followers_days(val) {
      const new_models = [{name: 'followers_days', value: this.$t('days').find((item) => item.value === val).value}]
      this.changeFilter('follower_days', new_models, `${this.$t('days').find((item) => item.value === val).text}, ${this.percentage_followers_growth}%`)
      if (this.loaded_services_history) this.getCounter()
    },
    percentage_followers_growth(val) {
      this.changeFilter('follower_days', [], `${this.$t('days').find((item) => item.value === this.followers_days).text}, ${val}%`)
    },
    increase_likes(val) {
      const new_models = [{name: 'increase_likes', value: this.$t('days').find((item) => item.value === val).value}]
      this.changeFilter('increase_likes', new_models,`${this.$t('days').find((item) => item.value === val).text}, ${this.percentage_increase_likes}%`)
      if (this.loaded_services_history) this.getCounter()
    },
    percentage_increase_likes(val) {
      this.changeFilter('increase_likes', [],`${this.$t('days').find((item) => item.value === this.increase_likes).text}, ${val}%`)
    },
    audience_ages(val) {
      const new_models = [{name: 'audience_ages', value: val[0] !== 10 || val[1] !== 65 ? val : null}]
      this.changeFilter('audience_ages', new_models, val[0] !== 10 || val[1] !== 65 ? this.getAudienceAge() : this.$t('search.realty.any'))

      if (val[0] === 10 && val[1] === 65) {
        this.percentage_audience_age = 10;
      }
    },
    percentage_audience_age() {
      this.changeFilter('audience_ages', [], this.getAudienceAge())
    },
    prices(val) {
      const new_models = [{name: 'prices', value: val[0] === 0 && val[1] === 1000000 ? null : val}]
      this.changeFilter('prices', new_models, val[0] === 0 && val[1] === 1000000 ? null : this.getLabelPrices())
    },
    total_likes(val) {
      const new_models = [{name: 'total_likes', value: val[0] !== 0 || val[1] !== 10000000 ? val : null}]
      this.changeFilter('total_likes', new_models, val[0] !== 0 || val[1] !== 10000000 ? this.getLabelLikes() : null)
    },
    engagement_rate(val) {
      const new_models = [{name: 'engagement_rate', value: val !== 0 ? val : null}]
      this.changeFilter('engagement_rate', new_models, val !== 0 ? val : null)
    },
    average_likes(val) {
      const new_models = [{name: 'average_likes', value: val !== 0 ? val : null}]
      this.changeFilter('average_likes', new_models, val !== 0 ? separatebycomma(val) : null)
    },
    average_comments(val) {
      const new_models = [{name: 'average_comments', value: val !== 0 ? val : null}]
      this.changeFilter('average_comments', new_models, val !== 0 ? separatebycomma(val) : null)
    },
    average_shares(val) {
      const new_models = [{name: 'average_shares', value: val !== 0 ? val : null}]
      this.changeFilter('average_shares', new_models, val !== 0 ? separatebycomma(val) : null)
    },
    most_recent(val) { 
      const new_models = [{name: 'most_recent', value: val !== 0 ? val : null}]
      this.changeFilter('most_recent', new_models, val !== 0 ? val : null)
    },
    views(val) {
      const new_models = [{name: 'views', value: val[0] !== 0 || val[1] !== 10000000 ? val : null}]
      this.changeFilter('views', new_models, val[0] !== 0 || val[1] !== 10000000 ? this.getLabelViews() : null)
    },
    mentioned_any(val) {
      const new_models = [{name: 'mentioned_any', value: val ? val : null}]
      this.changeFilter('mentioned_any', new_models, this.$t('search.realty.any'))

      this.selected_brand = null
      this.brand = ''
      this.brands = []

      const new_models_brand = [
        {name: 'mention', value: null},
        {name: 'brand', value: null}
      ]
      this.changeFilter('mention', new_models_brand, null)
      if (this.loaded_services_history) this.getCounter()
    },
    mention(val) {
      if (this.selected_brand) {
        const new_models = [
          {name: 'mention', value: val},
          {name: 'brand', value: this.brand}
        ]
        this.changeFilter('mention', new_models, this.brand)

        const filter = this.filters.find(item => item.filter === 'mention')
        filter.text = `${this.$t('optionsMencionado').find((item) => item.value === val).text}`
        if (this.loaded_services_history) this.getCounter() 
      }
    }
  }
}
</script>
<style lang="scss">
.select-network-search {
  width: auto;
  @media(max-width: 991px) {
    width: 100% !important;
  }
}
#card-tiktok-filters > .card-body {
  padding: 8px 20px 8px 8px;
  display: flex;
  align-items: center;
  @media(max-width: 991px) {
    flex-wrap: wrap;
    padding: 8px;
  }
}
.spinner-location-influencer-tiktok {
  position: absolute; 
  right: 9px; 
  top: 4px
}
</style>
<style lang="scss" scoped>
.p-custom {
  padding: 0 1.28rem 0.5rem 1.28rem;
}
.p-05 {
  padding: 0.5em;
}
.mr-03 {
  margin-right: 0.3em;
}
.container-more-filters {
  @media(max-width: 991px) {
    width: 100%;
    margin-top: 1em;
  }
  @media(min-width: 992px) {
    margin-left: 1em;
  }
}
</style>

